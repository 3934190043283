import React from 'react'
import OrganizationChart from '@dabeng/react-orgchart'
import {CustomNode} from './Custom-node'
import { InviteObject} from '../../../../api/model/ContactInfo'

interface InviteData {
    data: InviteObject[],
    username: string
}

export const FamilyShareTreeView: React.FC<InviteData> = ({
  data, username
}) => {

  const restructureData = (inviteData) => {
    const data = {
      name: '',
      link: '',
      children: [],
      current: false,
      relation: ''
    }
    inviteData.forEach((item) => {
      if (!data.name) {
        data.name = item.sender
        data.link = item.link
        data.current = data.name === username
      }
      const child = {
        name: item.recipient,
        link: item.link,
        current: item.recipient === username,
        relation: item.relationship
      }
      const existingChild = data.children.find(child => child.name === item.recipient)
      if (!existingChild) {
        if (item.sender !== data.name) {
          const parent = data.children.find(child => child.name === item.sender)
          if (parent) {
            parent.children = parent.children || []
            parent.children.push(child)
          }
        } else {
          data.children.push(child)
        }
      }
    })
    return data
  }

  return (
    <OrganizationChart
      datasource={restructureData(data)}
      collapsible={false}
      chartClass="myChart"
      NodeTemplate={CustomNode}
    />
  )
}
